import React, { useEffect, useCallback } from 'react';

import { useMutation, useQuery } from '@apollo/react-hooks';

import { notification, Switch } from 'antd';

import { withApollo } from 'react-apollo';
import { DISCONNECT_INSTAGRAM } from '../../core/gql/mutations';
import buttonWithTooltip from '../../decorators/buttonWithTooltip';
import { PROFILE } from '../../core/gql/queries';

const AUTH_URL =
    'https://api.instagram.com/oauth/authorize?app_id={app-id}&redirect_uri={redirect-uri}&scope=user_profile,user_media&response_type=code';

const { REACT_APP_INSTAGRAM_APP_ID, REACT_APP_INSTAGRAM_REDIRECT_URL } = window;

const InstagramSwitch = ({ client }) => {
    const [disconnectInstagram] = useMutation(DISCONNECT_INSTAGRAM);
    const { loading, error, data } = useQuery(PROFILE);
    const instagramAuthUrl = AUTH_URL.replace('{app-id}', REACT_APP_INSTAGRAM_APP_ID).replace(
        '{redirect-uri}',
        REACT_APP_INSTAGRAM_REDIRECT_URL,
    );

    const handleInstagramChange = (value) => {
        const data = client.readQuery({ query: PROFILE });

        client.writeQuery({
            query: PROFILE,
            data: {
                ...data,
                me: {
                    ...data.me,
                    instagramConnection: {
                        ...(data.me.instagramConnection || {}),
                        active: value,
                        error: null,
                    },
                },
            },
        });
    };

    const receiveMessage = useCallback(
        ({ data }) => {
            if (data === 'success') {
                handleInstagramChange(true);
            } else if (data === 'error') {
                handleInstagramChange(false);
                notification.error({
                    message: 'Fehler beim Verbinden zu Instagram',
                    description:
                        'Fehler bei der Synchronisation. Verbinden Sie Ihren Instagram Account erneut!',
                });
            }
        },
        [handleInstagramChange],
    );

    const handleSwitchChange = (value) => {
        if (value) {
            window.open(
                instagramAuthUrl,
                '_blank',
                'location=yes,height=570,width=520,scrollbars=yes,status=yes',
            );
        } else {
            handleInstagramChange(value);
            disconnectInstagram();
        }
    };

    useEffect(() => {
        window.addEventListener('message', receiveMessage, false);
        return () => window.removeEventListener('message', receiveMessage, false);
    }, [receiveMessage]);

    if (loading || error || !data?.me) {
        return null;
    }

    const { instagramConnection = null } = data.me;

    if (instagramConnection?.error) {
        notification.error({
            message: 'Fehler beim Verbinden zu Instagram',
            description:
                'Fehler bei der Synchronisation. Verbinden Sie Ihren Instagram Account erneut!',
        });
    }

    return (
        <>
            {buttonWithTooltip(
                <span>Verknüpfen Sie Ihren Instagram-Account und Ihre Beiträge.</span>,
            )(
                <Switch
                    style={{ position: 'absolute', right: '10px' }}
                    loading={loading}
                    checked={instagramConnection?.active && !instagramConnection?.error}
                    onChange={handleSwitchChange}
                />,
            )}
        </>
    );
};

export default withApollo(InstagramSwitch);
