import React, { useEffect, useState } from 'react';
import { Stats } from 'react-instantsearch';
import { Button, Icon, Modal } from 'antd';
import useMeiliSearch from '../../../../core/api/MeileSearchClient';
import CustomSearchBox from '../instantSearch/SearchBox';
import StudentsProfileSearchFilter from '../instantSearch/StudentsProfileSearchFilter';
import CustomInfiniteHits from '../InfiniteHits/CustomInfiniteHits';
import SortBy from '../instantSearch/SortBy';

const filterPanels = [{ key: 1, header: 'Status', attribute: 'status', search: false }];
const sortByApi = [
    {
        label: 'Neueste',
        value: 'talent_profiles_contact_requests:updatedAt:desc',
    },
    {
        label: 'Älteste',
        value: 'talent_profiles_contact_requests:updatedAt:asc',
    },
];
const StudentsContactedLayout = ({ title }) => {
    const { client } = useMeiliSearch();
    const [showFilterAsButton, setShowFilterAsButton] = useState(window.innerWidth < 1400);
    const [showMenu, setShowMenu] = useState(false);
    const [filter, setFilter] = useState('');
    const [searchFilter, setSearchFilter] = useState('');
    const [profilesLimit, setProfilesLimit] = useState(20);

    const handleFilterButtonClick = () => {
        setShowMenu(!showMenu); // Toggle menu visibility
    };
    const handleResize = () => {
        setShowFilterAsButton(window.innerWidth < 1400);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingBottom: '32px',
                    position: 'relative',
                }}>
                <div style={{ fontSize: '40px', fontWeight: 600, color: '#37373B' }}>{title}</div>
                <Stats
                    translations={{
                        rootElementText({ nbHits }) {
                            setProfilesLimit(nbHits);
                            return `${nbHits} Profile`;
                        },
                    }}
                />
            </div>
            <div style={{ display: showMenu ? 'none' : 'block' }}>
                <Modal
                    visible={showMenu}
                    className="affected-modal"
                    style={{
                        left: 0,
                        top: 0,
                        margin: 0,
                        padding: 0,
                        minHeight: '100vh',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                    title={
                        <div style={{ display: 'flex', alignItems: 'center', border: 'none' }}>
                            <Icon
                                style={{ fontSize: '24px', paddingRight: '10px' }}
                                type="filter"
                            />
                            <div
                                style={{
                                    lineHeight: '36px',
                                    color: '#37373B',
                                    fontSize: '24px',
                                    fontWeight: 600,
                                }}>
                                Filter
                            </div>
                        </div>
                    }
                    onCancel={() => setShowMenu(false)}
                    centered={false}
                    bodyStyle={{ display: 'flex', justifyContent: 'flex-end', padding: '0 16px' }}
                    footer={null}>
                    <div style={{ padding: '16px' }}>
                        <StudentsProfileSearchFilter
                            filterPanels={filterPanels}
                            setSearchFilter={setSearchFilter}
                            setFilter={setFilter}
                            responsive
                            client={client.searchClient}
                        />
                    </div>
                </Modal>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', gap: '32px' }}>
                {showFilterAsButton ? null : (
                    <div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Icon
                                type="filter"
                                style={{ fontSize: '20px', color: '#37373B', marginRight: '10px' }}
                            />
                            <div
                                style={{
                                    lineHeight: '36px',
                                    color: '#37373B',
                                    fontSize: '24px',
                                    fontWeight: 600,
                                }}>
                                Filter
                            </div>
                        </div>
                        <StudentsProfileSearchFilter
                            filterPanels={filterPanels}
                            setFilter={setFilter}
                            setSearchFilter={setSearchFilter}
                            responsive={false}
                            client={client.searchClient}
                        />
                    </div>
                )}

                <div style={{ width: '100%' }}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginBottom: '32px',
                        }}>
                        <div>
                            <CustomSearchBox />
                            {showFilterAsButton ? (
                                <Button
                                    key="filter"
                                    icon="filter"
                                    onClick={handleFilterButtonClick}
                                    style={{
                                        margin: '0 8px',
                                        background: '#ffffff',
                                        borderColor: '#F42847',
                                        color: '#F42847',
                                        borderRadius: '99999px',
                                        lineHeight: '24px',
                                        fontWeight: 600,
                                        display: 'inline-flex',
                                        alignItems: 'center',
                                        padding: '0 8px',
                                    }}>
                                    Filter
                                </Button>
                            ) : null}
                        </div>
                        <SortBy items={sortByApi} />
                    </div>
                    <CustomInfiniteHits
                        contacted={true}
                        profileLimit={profilesLimit}
                        searchFilter={searchFilter}
                        filter={filter}
                    />
                </div>
            </div>
        </>
    );
};

export default StudentsContactedLayout;
