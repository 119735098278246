import React, { useEffect, useState } from 'react';
import { Checkbox, Collapse, Icon, Input } from 'antd';
import { useRefinementList } from 'react-instantsearch';
import { useLocation } from 'react-router-dom';
import qs from 'qs';

const { Panel } = Collapse;

const getStatus = (status) => {
    switch (status) {
        case 'pending':
            return 'ausstehend';
        case 'accepted':
            return 'freigegeben';
        case 'deleted':
            return 'gelöscht';
        case 'declined':
            return 'abgelehnt';
        default:
            'abgelehnt';
    }
};

const RefinementListSearchInput = ({ onChange, placeholder }) => {
    const [searchValue, setSearchValue] = useState('');

    const handleSearchChange = (e) => {
        const newSearchValue = e.target.value;

        setSearchValue(newSearchValue);
        onChange(newSearchValue);
    };

    return (
        <Input
            style={{ marginBottom: '26px' }}
            type="text"
            value={searchValue}
            onChange={handleSearchChange}
            placeholder={placeholder}
            prefix={<Icon type="search" style={{ color: '#82828C' }} />}
        />
    );
};

const CustomRefinementList = (props) => {
    const { items, refine, isShowingMore, toggleShowMore } = useRefinementList(props);

    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = (term) => {
        setSearchTerm(term);
    };

    const filteredItems = items.filter((item) =>
        item.label.toLowerCase().includes(searchTerm.toLowerCase()),
    );

    const itemsToDisplay = isShowingMore ? filteredItems : filteredItems.slice(0, 10);

    return (
        <>
            {props.searchable && (
                <RefinementListSearchInput
                    placeholder={props.placeholder}
                    onChange={handleSearch}
                />
            )}
            <ul
                style={{
                    listStyleType: 'none',
                    padding: 0,
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                {itemsToDisplay.map((item) => (
                    <li
                        key={item.label}
                        style={{
                            fontSize: '14px',
                            marginBottom: '18px',
                            display: 'flex',
                            alignItems: 'center',
                            color: '#1A1D27',
                        }}>
                        <Checkbox
                            style={{ paddingRight: '8px' }}
                            checked={item.isRefined}
                            onChange={() => {
                                props.setFilter(item.value[0]);
                                refine(item.value);
                            }}
                        />
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div>
                                {props.header === 'Status' ? getStatus(item.label) : item.label}
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
            {filteredItems.length > 10 && (
                <button
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#F42847',
                        fontWeight: 600,
                    }}
                    onClick={toggleShowMore}>
                    {isShowingMore ? (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                lineHeight: '24px',
                            }}>
                            <Icon style={{ marginRight: '8px' }} type="minus" />
                            Weniger anzeigen
                        </div>
                    ) : (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                lineHeight: '24px',
                            }}>
                            <Icon style={{ marginRight: '8px' }} type="plus" />
                            Mehr anzeigen
                        </div>
                    )}
                </button>
            )}
        </>
    );
};

const StudentsProfileSearchFilter = ({ responsive, setFilter, setSearchFilter, filterPanels }) => {
    const location = useLocation();
    const [activeKeys, setActiveKeys] = useState([]);
    const expandIcon = ({ isActive }) => (isActive ? <Icon type="up" /> : <Icon type="down" />);

    // Set default key to expand the Collapse when url contains attributes
    useEffect(() => {
        const searchState = qs.parse(location.search, { ignoreQueryPrefix: true });
        const activePanelKeys = [];

        filterPanels.forEach((panel) => {
            const hasCreatedAtDesc =
                searchState['talent_profiles:createdAt:desc']?.refinementList?.[panel.attribute];
            const hasCreatedAtAsc =
                searchState['talent_profiles:createdAt:asc']?.refinementList?.[panel.attribute];
            const hasContactRequestsDesc =
                searchState['talent_profiles_contact_requests:updatedAt:desc']?.refinementList?.[
                    panel.attribute
                ];
            const hasContactRequestsAsc =
                searchState['talent_profiles_contact_requests:updatedAt:asc']?.refinementList?.[
                    panel.attribute
                ];

            if (
                hasCreatedAtDesc ||
                hasCreatedAtAsc ||
                hasContactRequestsDesc ||
                hasContactRequestsAsc
            ) {
                activePanelKeys.push(panel.key);
            }
        });

        setActiveKeys(activePanelKeys);
    }, [location.search]);

    return (
        <Collapse
            defaultActiveKey={activeKeys}
            style={{ backgroundColor: 'transparent', width: '297px' }}
            expandIconPosition="right"
            expandIcon={expandIcon}
            className="FilterPanelCollapse"
            bordered={false}
            activeKey={activeKeys}
            onChange={(keys) => setActiveKeys(keys)}>
            {filterPanels.map((panel) =>
                panel.attribute === 'id' ? (
                    <Panel
                        showArrow={true}
                        className="filterCollapse"
                        style={{ border: 'none', fontSize: '16px', display: 'none' }}
                        key={panel.key}
                        header={panel.header}>
                        <CustomRefinementList
                            header={panel.header}
                            showMore
                            responsive={responsive}
                            setFilter={setFilter}
                            attribute={panel.attribute}
                            placeholder={panel.placeholder}
                            setSearchFilter={setSearchFilter}
                            showMoreLimit={999999999}
                            searchable={panel.search}
                        />
                    </Panel>
                ) : (
                    <Panel
                        showArrow={true}
                        className="filterCollapse"
                        style={{ border: 'none', fontSize: '16px' }}
                        key={panel.key}
                        header={panel.header}>
                        <CustomRefinementList
                            limit={99999999}
                            header={panel.header}
                            showMore
                            responsive={responsive}
                            setFilter={setFilter}
                            attribute={panel.attribute}
                            placeholder={panel.placeholder}
                            setSearchFilter={setSearchFilter}
                            showMoreLimit={9999999999999}
                            searchable={panel.search}
                        />
                    </Panel>
                ),
            )}
        </Collapse>
    );
};

export default StudentsProfileSearchFilter;
