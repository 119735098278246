import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Modal, Form, Radio, Input } from 'antd';

export const reasons = [
    {
        key: 'EMPLOYED_UNINOW',
        text: 'Die Stelle wurde durch einen Aufruf in der UniNow App besetzt.',
    },
    {
        key: 'EMPLOYED_OTHER',
        text: 'Die Stelle wurde über einen anderen Kanal besetzt.',
    },
    {
        key: 'CANCELED',
        text: 'Die Stelle wird nicht mehr ausgeschrieben.',
    },
];

class ChangeVisibiltyButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showReasonModal: props.defaultVisible || false,
            alert: false,
            reason: props.lastReason,
        };
    }

    triggerModal = () => {
        this.setState({
            showReasonModal: true,
        });
    };

    submitReasonModal = () => {
        const { onSend } = this.props;
        const { getFieldValue } = this.props.form;
        const { reason } = this.state;
        if (reason === 'OTHER') {
            const reasonText = getFieldValue('reasonText') || '';
            if (reasonText.trim().length === 0) {
                this.setState({
                    alert: true,
                });
            } else {
                this.setState({ showReasonModal: false, alert: false }, () => onSend(reasonText));
            }
        } else if (reason) {
            this.setState({ showReasonModal: false, alert: false }, () => onSend(reason));
        } else {
            this.setState({
                alert: true,
            });
        }
    };

    cancelReasonModal = () => {
        this.setState({
            showReasonModal: false,
            alert: false,
        });
    };

    changeSelectedReason = (reason) => {
        this.setState({
            reason,
        });
    };

    render() {
        const { button, lastReason, title } = this.props;
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const { showReasonModal, alert, reason } = this.state;

        const defaultSelectdReason = lastReason
            ? reasons.find((reason) => reason.key === lastReason)
                ? lastReason
                : 'OTHER'
            : undefined;

        return (
            <div>
                <Modal
                    visible={showReasonModal}
                    title={
                        title || 'Bitte sagen Sie uns warum Sie Ihre Stellenanzeige deaktivieren'
                    }
                    onOk={this.submitReasonModal}
                    onCancel={this.cancelReasonModal}
                    okButtonProps={{
                        disabled: reason
                            ? reasons.find((elem) => elem.key === reason)
                                ? false
                                : (getFieldValue('reasonText') || '').trim().length === 0
                            : true,
                    }}
                    destroyOnClose
                    width={600}
                    cancelText="Abbrechen"
                    okText="Senden">
                    <Form.Item
                        help={
                            alert && (
                                <span style={{ color: '#f5222d' }}>
                                    Bitte geben Sie einen Grund an.
                                </span>
                            )
                        }
                        style={{ marginLeft: 10 }}>
                        <Radio.Group
                            onChange={({ target }) => this.changeSelectedReason(target.value)}
                            defaultValue={defaultSelectdReason}>
                            {reasons.map((reason, key) => (
                                <Radio
                                    value={reason.key}
                                    key={key}
                                    style={{ margin: '5px 0px', display: 'block' }}>
                                    {reason.text}
                                </Radio>
                            ))}
                            <Radio
                                value="OTHER"
                                key="OTHER"
                                style={{ margin: '5px 0px', display: 'block' }}>
                                Anderer Grund:{' '}
                                {getFieldDecorator('reasonText', {
                                    initialValue:
                                        defaultSelectdReason === 'OTHER' ? lastReason : '',
                                })(<Input size="small" style={{ width: 300 }} />)}
                            </Radio>
                        </Radio.Group>
                    </Form.Item>
                </Modal>
                {button && button(this.triggerModal)}
            </div>
        );
    }
}

ChangeVisibiltyButton.PropTypes = {
    onSend: PropTypes.func.isRequired,
    lastReason: PropTypes.string,
    button: PropTypes.node,
    title: PropTypes.string,
    defaultVisible: PropTypes.bool,
};

export default Form.create()(ChangeVisibiltyButton);
