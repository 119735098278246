import React, { useEffect, useState } from 'react';
import { Button, Tabs, Tag, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import ContactModal from '../Search/Modal/ContactModal';
import { useInstantSearch } from 'react-instantsearch';

const { TabPane } = Tabs;

const maxValueLength = 38;

function formatDate(dateInt) {
    const date = new Date(dateInt);

    if (!(date instanceof Date && !isNaN(date.getTime()))) {
        return '';
    }
    // fromat data
    return (
        'Erstellt am ' +
        date.toLocaleString('de-DE', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        })
    );
}

const renderInfoRows = (infoArray, windowWidth) => {
    return infoArray.map(({ label, value }) => (
        <div
            key={label}
            style={{
                display: 'flex',
                color: '#37373B',
                justifyContent: 'space-between',
                marginLeft: windowWidth < 850 ? '16px' : 0,
            }}>
            <div
                style={{
                    minWidth: '175px',
                    fontSize: '16px',
                    fontWeight: 600,
                    flex: 1,
                }}>
                {label}
            </div>
            <div
                style={{
                    fontSize: '16px',
                    color: '#37373B',
                    overflowWrap: 'break-word',
                    textAlign: 'right',
                    marginBottom: '8px',
                    maxWidth: '200px',
                }}>
                {value && value.length > maxValueLength ? (
                    <Tooltip title={value}>{value.substring(0, maxValueLength)}...</Tooltip>
                ) : (
                    value
                )}
            </div>
        </div>
    ));
};

export default function Hit({ hit, refresh, filter, searchFilter }) {
    const { indexUiState } = useInstantSearch();
    const [contactModalVisible, setContactModalVisible] = useState(false);
    const [selectedStudy, setSelectedStudy] = useState({});
    const windowWidth = window.innerWidth;
    const [activeTabKey, setActiveTabKey] = useState('');

    useEffect(() => {
        if (hit?.studies?.length > 0) {
            setSelectedStudy(hit.studies[0]);
            setActiveTabKey(hit.studies[0].studyName);
        }
    }, [hit.studies]);

    useEffect(() => {
        // check checkboxes and search box
        const overallFilter = indexUiState.query ? indexUiState.query.trim().toLowerCase() : '';
        // make first tab active by default
        setActiveTabKey(hit?.studies?.[0]?.studyName);
        setSelectedStudy(hit.studies[0]);

        if (!overallFilter) {
            setActiveTabKey(hit?.studies?.[0]?.studyName);
            setSelectedStudy(hit.studies[0]);
            return;
        }

        // check studies array
        const matchedStudy = hit.studies.find((study) =>
            [study.studyName, study.degree, study.startingSemester].some((field) =>
                field.toLowerCase().includes(overallFilter),
            ),
        );

        // if some value from studies or university value is matching the filter - make tab active
        if (
            matchedStudy ||
            (hit.university && hit.university.toLowerCase().includes(overallFilter))
        ) {
            const targetStudy = matchedStudy || hit.studies[0];
            setActiveTabKey(targetStudy.studyName);
            setSelectedStudy(targetStudy);
        }
    }, [filter, searchFilter, hit.studies, hit.university]);

    const handleOk = () => {
        setContactModalVisible(false);
    };

    const handleCancel = () => {
        setContactModalVisible(false);
    };

    return (
        <div
            className="studentCard"
            key={hit.id}
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
            }}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                    paddingBottom: '32px',
                }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                    }}>
                    <div
                        style={{
                            fontSize: '24px',
                            fontWeight: '600',
                            lineHeight: '32px',
                            overflowWrap: 'break-word',
                            width: '170px',
                            color: '#1A1D27',
                        }}>
                        Anonym
                    </div>
                    <span>{formatDate(hit.createdAt)}</span>
                </div>
                <Tag
                    style={{
                        borderRadius: '9999px',
                        fontSize: '14px',
                        padding: '8px 12px',
                        lineHeight: '20px',
                        border: 'none',
                        fontWeight: 600,
                        marginLeft: '0',
                    }}>
                    Nicht kontaktiert
                </Tag>
            </div>
            {hit?.studies?.length > 1 ? (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}>
                    <div>
                        <Tabs
                            tabBarStyle={{
                                border: 'none',
                                color: '#5C5C63',
                            }}
                            tabBarGutter={4}
                            size="small"
                            activeKey={activeTabKey}
                            onChange={(key) => {
                                const newSelectedStudy = hit.studies.find(
                                    (study) => study.studyName === key,
                                );
                                setSelectedStudy(newSelectedStudy);
                                setActiveTabKey(key);
                            }}>
                            <TabPane
                                className="custom"
                                tab="Studium 1"
                                key={hit.studies[0].studyName}
                            />
                            <TabPane tab="Studium 2" key={hit.studies[1].studyName} />
                        </Tabs>
                        {renderInfoRows(
                            [
                                { label: 'Hochschule', value: hit.university },
                                {
                                    label: 'Studiengang',
                                    value: selectedStudy.studyName,
                                },
                                {
                                    label: 'Angestr. Abschluss',
                                    value: selectedStudy.degree,
                                },
                                {
                                    label: 'Startsemester',
                                    value: selectedStudy.startingSemester,
                                },
                                {
                                    label: 'E-Mailadresse',
                                    value: 'Nicht freigegeben',
                                },
                                {
                                    label: 'Telefonnummer',
                                    value: 'Nicht freigegeben',
                                },
                            ],
                            windowWidth,
                        )}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}>
                        <Button
                            style={{ background: '#F42847' }}
                            type="primary"
                            onClick={() => setContactModalVisible(true)}>
                            Kontaktanfrage
                        </Button>
                    </div>
                </div>
            ) : (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        width: '100%',
                        justifyContent: 'space-between',
                    }}>
                    <div
                        style={{
                            paddingBottom: '32px',
                        }}>
                        {renderInfoRows(
                            [
                                { label: 'Hochschule', value: hit.university },
                                {
                                    label: 'Studiengang',
                                    value: selectedStudy.studyName,
                                },
                                {
                                    label: 'Angestr. Abschluss',
                                    value: selectedStudy.degree,
                                },
                                {
                                    label: 'Startsemester',
                                    value: selectedStudy.startingSemester,
                                },
                                {
                                    label: 'E-Mailadresse',
                                    value: 'Nicht freigegeben',
                                },
                                {
                                    label: 'Telefonnummer',
                                    value: 'Nicht freigegeben',
                                },
                            ],
                            windowWidth,
                        )}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}>
                        <Button
                            style={{ background: '#F42847' }}
                            type="primary"
                            onClick={() => setContactModalVisible(true)}>
                            Kontaktanfrage
                        </Button>
                    </div>
                </div>
            )}
            <ContactModal
                refresh={refresh}
                hit={hit}
                visible={contactModalVisible}
                handleOk={handleOk}
                handleCancel={handleCancel}
            />
        </div>
    );
}

Hit.propTypes = {
    hit: PropTypes.objectOf(PropTypes.any).isRequired,
};
