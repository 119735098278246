import React, { useState, useEffect } from 'react';
import { useInfiniteHits, useInstantSearch } from 'react-instantsearch';
import Hit from '../instantSearch/Hit';
import { Progress } from 'antd';
import { Spinner } from '../../../../components';
import ContactedHit from '../instantSearch/ContactedHit';

function CustomInfiniteHits(props) {
    const { hits, showMore, isLastPage } = useInfiniteHits(props);
    const { status, refresh } = useInstantSearch();
    const hitsPerPage = 20;
    const totalProfiles = props.profileLimit;
    const initialCount = hits.length < hitsPerPage ? hits.length : hitsPerPage;
    const [profilesCount, setProfilesCount] = useState(initialCount);
    const [initialLoading, setInitialLoading] = useState(true);

    useEffect(() => {
        setProfilesCount(initialCount);
    }, [props.filter, initialCount]);

    useEffect(() => {
        if (initialLoading && hits.length > 0) {
            setInitialLoading(false);
        }
    }, [hits]);

    if (status === 'loading' || status === 'stalled' || initialLoading) {
        return (
            <Spinner
                style={{
                    height: '80vh',
                    alignItems: 'flex-start',
                }}
                tip="Daten werden geladen..."
            />
        );
    }

    return (
        <div>
            <div className="ais-InfiniteHits-list">
                {hits.map((hit) => (
                    <div key={hit.id} className="ais-InfiniteHits-item">
                        {!props.contacted ? (
                            <Hit
                                hit={hit}
                                searchFilter={props.searchFilter}
                                filter={props.filter}
                                refresh={refresh}
                            />
                        ) : (
                            <ContactedHit
                                hit={hit}
                                searchFilter={props.searchFilter}
                                filter={props.filter}
                            />
                        )}
                    </div>
                ))}
            </div>
            <div
                style={{
                    width: '294px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '0 auto',
                    textAlign: 'center',
                }}>
                <span
                    style={{
                        paddingBottom: '16px',
                        fontSize: '16px',
                    }}>
                    {profilesCount} von {totalProfiles}
                </span>
                <Progress
                    strokeColor={'#F42847'}
                    style={{
                        paddingBottom: '32px',
                    }}
                    size="default"
                    showInfo={false}
                    percent={(profilesCount / totalProfiles) * 100}
                    strokeWidth={10}
                />
                {profilesCount < totalProfiles && (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}>
                        <button
                            style={{
                                color: '#F42847',
                                border: '1.5px solid #F42847',
                                borderRadius: '9999px',
                                padding: '8px 16px',
                                lineHeight: '28px',
                                fontSize: '18px',
                                fontWeight: 600,
                            }}
                            onClick={() => {
                                showMore();
                                setProfilesCount((prevCount) => {
                                    const newCount = prevCount + hitsPerPage;
                                    return newCount > totalProfiles ? totalProfiles : newCount;
                                });
                            }}
                            disabled={isLastPage}>
                            Mehr anzeigen
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}

export default CustomInfiniteHits;
